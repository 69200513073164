import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { MonitorState } from './monitor.types'
import { generateDaysAndKeys } from './monitor.utilts'
import Couch from '@/store/couch' 
import { Log7DaysDTO } from '@/store/couch/types'
import { Base } from '@/store/bases/bases.types'

const MonitorState: Module<MonitorState, RootState> = {
  namespaced: true,
  state: {
    days: generateDaysAndKeys().days,
    daysLabels: generateDaysAndKeys().daysLabels,
    sortByDay: {
      day: null,
      sort: 'AUTO'
    },
    search: '',
    logs: [],
    starFilter: 'ALL',
    versionSelected: null,
    loading: {
      getLogs: false,
    },
    loaded: {
      getLogs: false,
    },
    error: {
      getLogs: null
    }
  },

  mutations: {
    loaded (state, { id, value }) {
      state.loaded[id] = value
    },
    loading (state, { id, value }) {
      state.loading[id] = value
    },
    error (state, { id, value }) {
      state.error[id] = value
    },
    setData (state, data) {
      state.logs = data
    },
    setSearch (state, payload) {
      state.search = payload
    },
    setVersionSelected (state, payload) {
      state.versionSelected = payload
    },
    setSortByDay (state, { day, sort }) {
      state.sortByDay.day = day
      state.sortByDay.sort = sort
    },
    setStarFilter (state, payload) {
      state.starFilter = payload
    }
  },

  actions: {
    toggleStarFilter ({ commit, state }) {
      const current = state.starFilter
      const next = current === 'ALL'
        ? 'STARRED'
        : current === 'STARRED'
        ? 'NONE'
        : 'ALL'
        commit('setStarFilter', next)
    },

    toggleSortByDay ({ commit, state }, dayToSort) {
      const currentSort = state.sortByDay.sort
      let toSort = currentSort === 'AUTO'
        ? 'ASC'
        : (currentSort === 'ASC')
        ? 'DESC'
        : 'AUTO' 

      if (dayToSort !== state.sortByDay.day) {
        toSort = 'ASC'
      }

      commit('setSortByDay', { day: dayToSort,  sort: toSort })
    },

    async getLogs ({ commit, state, dispatch }) {
      if (!state.loaded.getLogs) {
        commit('loading', { id: 'getLogs', value: true })
        try {
          await dispatch('bases/loadBases', null, { root: true })
          const logs = await Couch.log.getLog7Days()
          commit('setData', logs)
          commit('loaded', { id: 'getLogs', value: true })
        } catch {
          commit('error', { id: 'getLogs', value: 'Erro ao buscar dados' })
        } finally {
          commit('loading', { id: 'getLogs', value: false })
        }
      }
    },

    async setSearch ({ commit }, payload: string) {
      commit('setSearch', payload)
    },
  },

  getters: {
    filteredLogs (state, _, __, rootGetters) {
      const idToBase: Record<string, Base> = rootGetters['bases/idToBase']
      const filteredLogs: Log7DaysDTO[] = []
      for (const log of state.logs) {
        if (state.search && !log._id.toUpperCase().includes(state.search.toUpperCase())) {
          continue 
        }

        if (state.starFilter !== 'ALL') {
          if (!idToBase[log._id].star && state.starFilter === 'STARRED') {
            continue
          }

          if (idToBase[log._id].star && state.starFilter === 'NONE') {
            continue
          }
        }

        if (state.versionSelected && idToBase[log._id].workerVersion !== state.versionSelected) {
          continue
        }

        filteredLogs.push(log)
      }

      const dayToSort = state.sortByDay.day
      const sort = state.sortByDay.sort
      if (sort !== 'AUTO' && dayToSort !== null) {
        filteredLogs.sort((a, b) => {
          const aSuccesses = a.logs.days[dayToSort]?.successes ?? 0
          const bSuccesses = b.logs.days[dayToSort]?.successes ?? 0
          const aErrors = a.logs.days[dayToSort]?.errors ?? 0
          const bErrors = b.logs.days[dayToSort]?.errors ?? 0

          if (aErrors > 0 && bErrors === 0) {
            return sort === 'ASC' ? 1 : -1
          }

          if (aErrors === 0 && bErrors > 0) {
            return sort === 'ASC' ? -1 : 1
          }

          if (aErrors > 0 && bErrors > 0) {
            return sort === 'ASC' ? aErrors - bErrors : bErrors - aErrors
          }

          return sort === 'ASC' ? bSuccesses - aSuccesses : bErrors - aErrors
        })
      }

      filteredLogs.sort()

      return filteredLogs
    }
  }
}

export default MonitorState
