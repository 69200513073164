export function binarySearch(arr: Array<any>, id: string, idSelector: string = 'id'): number {
  let low = 0, high = arr.length, mid;

  while (low < high) {
    mid = (low + high) >>> 1;
    arr[mid][idSelector] < id ? low = mid + 1 : high = mid
  }

  return low
}
