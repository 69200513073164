
import { Component, Vue } from 'vue-property-decorator';
import BaseConnectedList from './Monitoramento/BaseConnectedList.vue'
import BaseCalendar from './Monitoramento/Calendar.vue'

@Component({
  components: {
    BaseConnectedList,
    BaseCalendar
  }
})
export default class Home extends Vue {
  search: string = ''

  created () {
    window.scrollTo(0, 0)
  }

  updateSearch () {
    //@ts-ignore
    this.$refs.baseConnectedList.search = this.search
    //@ts-ignore
    this.$refs.baseCalendar.search = this.search
  }

  keyDown ({ key }) {
    if (key === 'Enter') {
      this.updateSearch()
    }
  }

  async refresh () {
    this.$store.dispatch('backup/log/getResume', true)
    this.$store.dispatch('bases/getConnectedBases')
  }
}
