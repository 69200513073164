import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Middleware from '@/router/middleware'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
    component: () => import('@/views/Index.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        props: true,
        beforeEnter: Middleware.alreadyLoggedIn,
        component: () => import('@/views/Login.vue'),
      },
      {
        path: '/check-auth',
        name: 'check-auth',
        props: true,
        beforeEnter: Middleware.alreadyLoggedIn,
        component: () => import('@/views/CheckAuth.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/IndexDashboard.vue'),
    beforeEnter: Middleware.checkLogin,
    redirect: { name: 'bases' },
    children: [
      {
        path: 'old-monitor',
        name: 'old-monitor',
        component: () => import('@/views/OldMonitor.vue'),
        meta: {
          title: 'Monitoramento',
        }
      },
      {
        path: 'bases',
        name: 'bases',
        component: () => import('@/views/Bases/Bases.vue'),
        meta: {
          title: 'Administrar Bases',
        },
      },
      {
        path: 'backup',
        name: 'backup',
        beforeEnter: Middleware.alreadySelectedBase,
        component: () => import('@/views/Backup/Backup.vue'),
        meta: {
          title: 'Configurar Backup',
        },
      },
      {
        path: 'monitor',
        name: 'Monitor',
        component: () => import('@/views/Monitor.vue'),
        meta: {
          title: 'Monitoramento',
        }
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
