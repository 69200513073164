
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { RuleFunction } from '@/plugins/rules'

@Component
export default class VAgendamentoDiario extends Vue {
  @Prop()
  value!: Array<number>

  @Prop({
    default: () => []
  })
  rules!: Array<RuleFunction>

  @Prop({
    default: false
  })
  disabled!: boolean

  errorMsg: string = ''

  get arrayLength (): number {
    return this.value.length
  }

  @Watch('arrayLength')
  watchArrayLength () {
    for (const rule of this.rules) {
      this.errorMsg = rule(this.value) !== true ? 'Selecione pelo menos uma opção' : ''
    }
  }

  dayNames: Array<string> = [
    'Domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'Sábado',
  ]

  format (day: string) {
    if (this.$vuetify.breakpoint.xs)
      return day[0]
    if (this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.lgOnly)
      return day.split('-')[0]
    return day
  }

  click (day: number) {
    if (!this.disabled) {
      const index = this.value.findIndex(it => it === day)
      const arr = cloneDeep(this.value)
      
      if (index >= 0) {
        arr.splice(index, 1)
      } else {
        arr.push(day)
      }

      this.$emit('input', arr)
    }
  }
}
