
import { LastBackupLog } from '@/store/backup/log/log.types'
import { Base } from '@/store/bases/bases.types'
import { format } from 'date-fns'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BaseConnectedList extends Vue {
  search: string = ''

  get bases (): Array<Base> {
    return this.$store.state.bases.bases
  }

  get lastBackupLog (): { [id: string]: LastBackupLog } {
    return this.$store.state.backup.log.lastBackupLog
  } 

  get connectedBases (): { [id: string]: boolean } {
    const bases: Base[] = this.$store.state.bases.bases
    const connectedBases = this.$store.state.bases.connected
    const connected = {}

    for (const base of connectedBases) {
      connected[base] = true
    }

    for (const base of bases) {
      if (!connected[base.id]) {
        connected[base.id] = false
      }
    }

    return connected
  }

  get filteredBases (): Array<Base> {
    return this.bases.filter(it => {
      const label = it.label.toLowerCase()
      const search = this.search.toLowerCase()
      return label.search(search) >= 0
    })
  }

  mounted () {
    if (this.$store.state.bases.loaded.all) {
      this.setHeight()
    } else {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'bases/loadedAll') {
          this.setHeight()
        }
      })
    }

    window.addEventListener('resize', this.setHeight)
  }

  destroyed () {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight () {
    this.$nextTick(() => {
      const el = document.getElementById('container')
      if (el) {
        const height = window.innerHeight - el.getBoundingClientRect().top - 25
        el.style.height = `${height}px` 
      }
    })
  }

  formatDate (date: string) {
    if (date) {
      return format(new Date(date), 'HH:mm dd/MM/yyyy')
    }
    return null
  }

  setSearch (search: string) {
    this.search = search
  }
}
