
import { Log7DaysDTO, Log7DaysMessageDTO } from '@/store/couch/types'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const monitorModule = namespace('backup/monitor')

@Component
export default class MonitorDetailDialog extends Vue {
  filteredLogs: Record<string, Log7DaysMessageDTO[]> = {}

  @monitorModule.State('logs')
  logs!: Log7DaysDTO[]

  open (baseId: string, filterDay?: string) {
    this.filteredLogs = {}
    const logDocument = this.logs?.find(log => log._id === baseId)
    if (logDocument) {
      const days = Object.keys(logDocument.logs.days) ?? []
      days.sort().reverse()
      let found = false

      for (const day of days) {
        if (!filterDay || filterDay === day) {
          if (logDocument?.logs.days[day]) {
            found = true
            this.filteredLogs[day] = logDocument?.logs.days[day].messages
          }
        }
      }

      for (const day in this.filteredLogs) {
        this.filteredLogs[day].sort((a, b) => {
          return a.startTime < b.startTime ? -1 : 1 
        })
      }

      if (found) {
        this.enableDialog = true
      }
    }
  }

  async showError (text?: string) {
    if (text) {
      this.$dialog.error({
        title: "Erro:",
        text,
        persistent: false,
      });
    }
  }

  public enableDialog: boolean = false
}
