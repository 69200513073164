export class Base {
  constructor (
    public id: string,
    public label: string,
    public star: boolean,
    public enabled?: boolean,
    public workerVersion?: string
  ) {}
}

export interface BasesState {
  bases: Array<Base>;
  connected: Array<string>;
  triggersRegistered: boolean;
  loading: { [id: string]: boolean | string };
  error: { [id: string]: string | null};
  loaded: { [id: string]: boolean };
}
