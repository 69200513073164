
import { Base } from '@/store/bases/bases.types';
import { Component, Vue } from 'vue-property-decorator'

interface DrawerItem {
  icon: string;
  title: string;
  to: string;
  disabled?: boolean;
}

@Component
export default class Drawer extends Vue {
  loadingLogout: boolean = false
  
  get drawer(): boolean {
    return this.drawerState
  }
  set drawer(value: boolean) {
    this.$store.dispatch('core/setDrawer', value)
  }

  get currentBase (): Base {
    return this.$store.state.currentBase
  }

  get drawerState (): boolean {
    return this.$store.state.core.drawer
  }

  items: Array<DrawerItem> = [
    {
      icon: 'fa-database',
      title: 'Bases',
      to: '/dashboard/bases',
    },
    {
      icon: 'fa-hdd',
      title: 'Backup',
      to: '/dashboard/backup',
      disabled: true,
    },
    {
      icon: 'fa-binoculars',
      title: 'Monitoramento',
      to: '/dashboard/monitor'
    },
  ];

  async logout () {
    this.loadingLogout = true
    await this.$store.dispatch('auth/logout')
    this.loadingLogout = false
    this.$router.push({ name: 'login' })
  }
}
