
import { BackupConfig, BackupRule } from '@/store/backup/backup.types'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const backupModule = namespace('backup')

@Component
export default class VBackupRuleList extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  @backupModule.State('backupConfigCurrent')
  backupConfig!: BackupConfig

  ruleItem: BackupRule = new BackupRule();
  typeComboItems = [
    { value: 'exclusion', text: 'Excluir' },
    { value: 'overwrite', text: 'Sobrescrever' }
  ];
  iterationComboItems = [
    { value: 'daily', text: 'Diariamente' },
    { value: 'weekly', text: 'Semanalmente' },
    { value: 'monthly', text: 'Mensalmente' },
  ];

  iteration2text (iteration: string) {
    return this.iterationComboItems.find(it => it.value === iteration)?.text
  }
}
