import store from '@/store'
import router from '@/router/'

export default (to: any, from: any, next: any) => {
  if (!store.state.currentBase) {
    if (from.name === 'bases')
      return

    return router.push({ name: 'bases' })
  }
  return next()
}
