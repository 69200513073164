
import { Component, Vue } from 'vue-property-decorator'
import CoreAppBar from '@/views/core/AppBar.vue'
import CoreDrawer from '@/views/core/Drawer.vue'
import Snackbar from '@/views/core/Snackbar.vue'
import { Action } from 'vuex-class'

@Component({
  components: {
    CoreAppBar,
    CoreDrawer,
    Snackbar,
  },
})
export default class Index extends Vue {
  @Action('registerGlobalTriggers')
  registerGlobalTriggers!: () => void

  created () {
    this.registerGlobalTriggers()
  }
}
