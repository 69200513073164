import { Module } from 'vuex';
import { RootState, changeStatus } from '@/store/types'
import { BackupState, BackupConfig, SchemaBackupConfig, SchemaBackupOrder } from './backup.types'
import Couch from '@/store/couch'
import {Notifier} from '@/plugins/notifier';
import {binarySearch} from '../utils';
import log from './log/log.store'
import monitor from './monitor/monitor.store'
import event from './event/event.store'
import { LabelListDTO } from '../couch/types';
import Vue from 'vue'

const BackupState: Module<BackupState, RootState> = {
  namespaced: true,

  modules: {
    monitor,
    log,
    event
  },

  state: {
    log: undefined,
    labelList: [],
    backupConfigStatus: 'create',
    backupConfigCurrent: new BackupConfig(), 
    logListEnable: false,
    loading: {
      addBackup: false,
      loadLabelList: false,
      getBackupById: null,
      addOrder: null,
    },
    error: {
      addBackup: null,
      loadBackup: null,
    },
    triggersRegistered: false,
  },

  mutations: {
    toggleLogListEnable (state) {
      state.logListEnable = !state.logListEnable
    },

    setLogListEnable (state, payload) {
      state.logListEnable = payload
    },

    disableBackupConfig (state) {
      state.backupConfigCurrent = new BackupConfig()
      state.backupConfigStatus = 'disabled'
    },

    newBackupConfig (state) {
      state.backupConfigCurrent = new BackupConfig()
      state.backupConfigStatus = 'create'
    },

    setBackupConfigCurrent (state, { backupConfig, status }) {
      state.backupConfigCurrent = backupConfig
      state.backupConfigStatus = status
    },

    setTriggersRegistered (state, payload: boolean) {
      state.triggersRegistered = payload
    },

    loading (state, { id, value }) {
      state.loading[id] = value
    },

    changeLabelList (state, { index, value }) {
      state.labelList.splice(index, 1, value)
    },

    setLabelList (state, payload: LabelListDTO[]) {
      state.labelList = payload
      state.loading.loadLabelList = false
    },

    addLabelList (state, payload: LabelListDTO) {
      state.labelList.push(payload)
      state.loading.addBackup = false
    },

    insertLabelList (state, { index, value }) {
      state.labelList.splice(index, 0, value)
    },

    deleteLabelList (state, index) {
      state.labelList.splice(index, 1)
    },

    statusBackupConfig (state, status: 'create' | 'edit' | 'disabled') {
      state.backupConfigStatus = status
      state.backupConfigCurrent = new BackupConfig()
    },
  },

  actions: {
    registerTriggers ({ state, rootState, commit }) {
      Couch.backup.changes((err: any, backup: SchemaBackupConfig, status: changeStatus) => {
        if (err) {
          commit('showSnackbar', new Notifier('Problema de conexão', { erro: err.toString() }, 'danger'))
        } else if (!backup._id) {
          commit('showSnackbar', new Notifier('Problema de conexão', { erro: 'Backup id nulo' }, 'danger'))
        } else {
          const [baseId, id] = backup._id?.split(':')
          const index = state.labelList.findIndex(it => it.id === backup._id)
          let operation: Array<string | any>
          let snackOpt: any

          if (status !== changeStatus.DELETED) {
            const labelList: LabelListDTO = {
              id: backup._id,
              key: backup.label,
              value: {
                days: backup.days,
              },
            }
            if (index === -1) {
              snackOpt = { 'Criação': `novo backup criado "${backup.label}"` }
              const insertIndex = binarySearch(state.labelList, backup.label, 'key')
              operation = ['insertLabelList', { index: insertIndex, value: labelList }]
            } else {
              snackOpt = { 'Alteração': `Backup "${backup.label}" "${id}" foi alterado` }
              operation = ['changeLabelList', { index: index, value: labelList }]
            }
          } else {
            snackOpt = { 'Exclusão': `Backup "${id}" foi excluido` }
            operation = ['deleteLabelList', index]
          }

          if (baseId === rootState.currentBase?.id) {
            commit(operation[0], operation[1])
            commit('showSnackbar', new Notifier(`Alerta para ${baseId}`, snackOpt), { root: true })
          } else {
            commit('showSnackbar', new Notifier(`Alerta para ${baseId}`, snackOpt, 'primary', 'right', 'bottom'), { root: true })
          }
        }
      })
    },

    async addBackup ({ commit, rootState }, backup: BackupConfig) {
      commit('loading', { id: 'addBackup', value: true })
      await Couch.backup.add(backup.toJSON(rootState.currentBase?.id))
      const backupConfig = new BackupConfig()
      commit('setBackupConfigCurrent', { backupConfig, status: 'disabled' })
      commit('loading', { id: 'addBackup', value: false })
    },

    async addOrder ({ commit }, order: SchemaBackupOrder) {
      commit('loading', { id: 'addOrder', value: `${order.backupId}:${order.type}` })
      await Couch.backup.addOrder(order)
      commit('loading', { id: 'addOrder', value: false })
    },

    async getBackupById ({ commit }, id: string) {
      commit('loading', { id: 'getBackupById', value: id })
      const backup: SchemaBackupConfig = await Couch.backup.getBackupById(id)
      const backupConfig = new BackupConfig(backup)
      commit('setBackupConfigCurrent', { backupConfig, status: 'edit' })
      commit('loading', { id: 'getBackupById', value: null })
      return backup
    },
    
    async deleteBackupById ({ commit }, { id, rev }) {
      commit('loading', { id: 'deleteBackupById', value: id })
      await Couch.backup.deleteBackupById(id, rev)
      commit('loading', { id: 'deleteBackupById', value: null })
      commit('disableBackupConfig')
    },

    async loadLabelList ({ commit, rootState }) {
      const baseId = rootState.currentBase?.id
      if (baseId) {
        commit('loading', { id: 'loadLabelList', value: true })
        const labelList = await Couch.backup.getLabelList(baseId)
        commit('setLabelList', labelList)
      }
    },

    async startBackup (_, backupId: string) {
      Vue.prototype.$socketio.emitWait("start-backup", backupId)
    }
  },
}

export default BackupState
