
import router from '@/router';
import { Base } from '@/store/bases/bases.types';
import { Log7DaysDTO } from '@/store/couch/types';
import { throttle } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MonitorDetailDialog from './Monitor/MonitorDetailDialog.vue';

const monitorModule = namespace('backup/monitor')
const basesModule = namespace('bases')

@Component({
  components: {
    MonitorDetailDialog
  }
})
export default class Monitor extends Vue {
  search: string = ''
  dialogBaseId: string = ''

  @monitorModule.State('days')
  days!: string[]

  @monitorModule.State('daysLabels')
  daysLabels!: Record<string, string>

  @monitorModule.State('loading')
  loading!: Record<string, boolean>

  @monitorModule.State('sortByDay')
  sortByDay!: { day: string | null; sort: 'AUTO' | 'DESC' | 'ASC' }

  @monitorModule.State('starFilter')
  starFilter!: 'ALL' | 'STARRED' | 'NONE'

  @monitorModule.Getter('filteredLogs')
  logs!: Log7DaysDTO[]

  @monitorModule.Action('toggleStarFilter')
  toggleStarFilter!: () => void

  @monitorModule.Action('getLogs')
  getLogs!: () => Promise<void>

  @monitorModule.Action('setSearch')
  setSearch!: (search: string) => void

  @monitorModule.Action('toggleSortByDay')
  toggleSortByDay!: (dayToSort: string) => void

  @basesModule.State('loading')
  basesLoading!: Record<string, boolean>

  @basesModule.Getter('versions')
  basesVersions!: string[]

  @basesModule.Getter('idToLabel')
  baseIdToLabel!: Record<string, string>

  @basesModule.Getter('connectedBases')
  connectedBases!: Record<string, boolean>

  @basesModule.Getter('idToBase')
  idToBase!: Record<string, Base>

  tableHeight: string = "80vh";

  fakeLoading: boolean = true;

  get starFilterIcon (): { icon: string; color: string } {
    return this.starFilter === 'ALL'
      ? { color: 'orange', icon: 'far fa-star' }
      : this.starFilter === 'STARRED'
      ? { color: 'orange', icon: 'fa-star' }
      : { color: 'gray', icon: 'fa-star' }
  }

  get versionSelected (): string | null {
    return this.$store.state.backup.monitor.versionSelected
  }

  set versionSelected (value: string | null) {
    this.$store.commit('backup/monitor/setVersionSelected', value)
  }

  mounted () {
    this.getLogs()
    this.getTableBound()
    window.addEventListener('resize', throttle(this.getTableBound, 250))
    setTimeout(() => {
      this.fakeLoading = false
    }, 1e3)
  }

  getIconColor (log: Log7DaysDTO, day: string): { icon: string; color: string } {
    const successes = log.logs.days[day]?.successes ?? 0
    const errors = log.logs.days[day]?.errors ?? 0
    return successes === errors && errors === 0
      ? { icon: 'fa-exclamation-triangle', color: 'orange' }
      : errors > 0
      ? { icon: 'fa-exclamation-circle', color: 'red' }
      : { icon: 'fa-check-circle', color: 'green' }
  }

  getSortIcon (day: string) {
    if (this.sortByDay.day === day) {
      return this.sortByDay.sort === 'AUTO'
        ? 'fa-sort'
        : this.sortByDay.sort === 'ASC'
        ? 'fa-sort-up'
        : 'fa-sort-down' 
    } else {
      return 'fa-sort'
    }
  }

  getTableBound () {
    this.$nextTick(() => {
      // @ts-ignore
      const monitorTableEl = this.$refs.MonitorTable.$el
      if (monitorTableEl) {
        this.tableHeight = (window.innerHeight - monitorTableEl.getBoundingClientRect().top - 16).toFixed(0)
      }
    })
  }

  gotToOldMonitor () {
    router.push({ name: 'old-monitor' })
  }

  items = [
    'Programming',
    'Design',
    'Vue',
    'Vuetify',
  ]
}
