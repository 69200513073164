import store from '@/store/index'
import { NavigationGuardNext } from 'vue-router'
import Vue from 'vue'
import router from '@/router' 

export default (to: any, from: any, next: NavigationGuardNext<Vue>) => {
  if (!store.state.auth?.checkAuth || !store.state.auth?.socketConnected) {
    return router.push({ name: 'check-auth', params: { redirectTo: to }})
  }
  return next()
}
