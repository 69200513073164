
import { Notifier } from '@/plugins/notifier';
import { BackupConfig, BackupItem } from '@/store/backup/backup.types';
import { Component, Vue } from 'vue-property-decorator'
import { Action, namespace, State } from 'vuex-class'
import VAgendamentoDiario from './AgendamentoDiario.vue'
import VTimeList from './TimeList.vue'
import VBackupRules from './RuleList.vue'

const backupModule = namespace('backup')

@Component({
  components: {
    VAgendamentoDiario,
    VTimeList,
    VBackupRules,
  }
})
export default class VBackupConfig extends Vue {
  @State('isDev')
  isDev!: boolean

  @Action('backup/addBackup')
  addBackup!: (backup: BackupConfig) => void

  @backupModule.State('loading')
  loading!: { [id: string]: boolean }

  @backupModule.State('backupConfigStatus')
  status!: string

  @backupModule.State('backupConfigCurrent')
  backupConfig!: BackupConfig

  @backupModule.Mutation('newBackupConfig')
  newBackupConfig!: () => void;

  @backupModule.Mutation('disableBackupConfig')
  disableBackupConfig!: () => void;

  @backupModule.Action('deleteBackupById')
  deleteBackupById!: (id_rev: any) => Promise<void>

  backupItem: BackupItem = new BackupItem();

  get disabled () {
    return this.status === 'disabled'
  }

  icons: any = {
    file: 'fa-file',
    folder: 'fa-folder'
  }

  salvarHandler () {
    const isValid = this.backupConfig.validate()
    if (!isValid) {
      this.$notifier(new Notifier('Erro(s) de validação', this.backupConfig.error, 'error'))
    } else {
      this.addBackup(this.backupConfig)
    }
  }

  async deleteHandler () {
    const res = await this.$dialog.confirm({
      text: `Você realmente deseja excluir a configuração de backup "${this.backupConfig.label.model}"?`,
      title: "Aviso",
      persistent: false,
    });

    if (res === true) {
      await this.deleteBackupById({ id: this.backupConfig._id, rev: this.backupConfig._rev })
    }
  }
}
