import {Module} from "vuex";
import { UsersState } from '@/store/users/users.types'
import {RootState} from '../types';
import { isEmpty } from 'lodash'
import Couch from '@/store/couch'
import crypto from 'crypto'

const UsersState: Module<UsersState, RootState> = {
  namespaced: true,

  state: {
    users: {},
    error: {
      addUser: null,
      loadUsers: null,
    },
    loading: {
      addUser: false,
      loadUsers: false,
    },
  },

  mutations: {
    setUsers (state, payload) {
      state.users = payload
      state.loading.setUsers = false
    },

    addUser (state, { id, value }) {
      state.users[id] = value
      state.loading.addUser = false
    },

    loading (state, { id, value }) {
      state.loading[id] = value
    },
  },

  actions: {
    async loadUsers ({ state, commit }) {
      if (isEmpty(state.users)) {
        commit('loading', { id: 'loadUsers', value: true })
        const tokenPerUser = await Couch.users.all()
        commit('setUsers', tokenPerUser)
      }
    },

    async addUser ({ commit }, id: string) {
      commit('loading', { id: 'addUser', value: true })
      const password: string = crypto.randomBytes(20).toString('hex')
      await Couch.users.add(id, password)
      commit('addUser', { id, value: password })
    }
  }
}

export default UsersState
