import { Module } from 'vuex';
import { RootState } from '@/store/types'
import { AuthState, UserCredentials, getAuthFromLS, setAuthToLS, removeAuthFromLS } from '@/store/auth/auth.types'
import Couch from '@/store/couch'

const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,

  state: {
    authorization: getAuthFromLS().authorization,
    socketConnected: false,
    checkAuth: false,
    loggedIn: false,
    loginError: '',
    username: getAuthFromLS().name,
  },

  mutations: {
    loggedIn (state, { username, authorization }) {
      state.loggedIn = true;
      state.loginError = '';
      state.username = username
      state.authorization = authorization
    },

    loggedOut (state) {
      state.loggedIn = false;
      state.username = undefined;
      state.authorization = undefined;
      state.loginError = '';
      state.checkAuth = false;
    },

    checkAuth (state, check: boolean) {
      state.checkAuth = check 
    },

    setSocketConnected (state, payload: boolean) {
      state.socketConnected = payload
    },
  },

  actions: {
    async login ({ commit }, { username, password }: UserCredentials): Promise<boolean> {
      try {
        const authorization = btoa(`${username}:${password}`)
        await Couch.auth.checkSession(authorization)
        commit('loggedIn', { username, authorization })
        setAuthToLS(authorization)
        return true
      } catch (e) {
        commit('loggedOut')
        removeAuthFromLS()
      }

      return false
    },

    async logout ({ commit }): Promise<void> {
      try {
        commit('loggedOut')
      } catch (e) {
        console.error(e)
      }
    },

    async session ({ commit, state }): Promise<boolean> {
      if (!state.authorization) {
        return false
      }

      try {
        const { authorization, name } = getAuthFromLS()
        await Couch.auth.checkSession(state.authorization)
        commit('loggedIn', { username: name, authorization })
        return true
      } catch (e) {
        commit('loggedOut')
      }

      return false
    },

    checkAuth ({ commit }, check: boolean) {
      commit('checkAuth', check)
    }
  },
} 

export default AuthModule
