import { Module } from 'vuex'
import { RootState, ActionError, changeStatus } from '@/store/types'
import { BasesState, Base } from '@/store/bases/bases.types'
import Couch from '@/store/couch' 
import {binarySearch} from '../utils'
import Vue from 'vue'
import {Notifier} from '@/plugins/notifier'

const BasesState: Module<BasesState, RootState> = {
  namespaced: true,

  state: {
    bases: [],
    connected: [],
    triggersRegistered: false,
    error: {
      addBase: null,
    },
    loading: {
      addBase: false,
      loadBases: false,
      getConnectedBases: false,
      toggleStar: ''
    },
    loaded: {
      getConnectedBases: false,
      loadBases: false,
      all: false, // bases  
    },
  },

  mutations: {
    setTriggersRegistered (state, payload: boolean) {
      state.triggersRegistered = payload
    },

    setBases (state, payload: Array<Base>) {
      state.bases = payload
    },

    setConnected (state, payload: string[]) {
      state.connected = payload
    },

    addBase (state, payload: Base) {
      state.bases.unshift(payload)
      state.loading.addBase = false
    },

    deleteBase (state, index: number) {
      state.bases.splice(index, 1)
    },

    setBaseIndex (state, { index, base }) {
      Vue.set(state.bases, index, base)
    },

    error (state, payload: ActionError) {
      state.error[payload.errorId] = payload.errorMsg
    },

    loading (state, { id, value }) {
      state.loading[id] = value
    },

    loaded (state, { id, value }) {
      state.loaded[id] = value
    },

    loadedAll (state) {
      state.loaded.all = true
    },
  },

  actions: {
    registerTriggers ({ state, commit, dispatch }) {
      if (!state.triggersRegistered) {
        commit('setTriggersRegistered', true)
        const allChangesCallback = (err: any, base: Base, status: changeStatus) => {
          if (err) {
            err
          } else {
            const index = binarySearch(state.bases, base.id)
            const currBase = state.bases[index]
            if (status !== changeStatus.DELETED) {
              if (currBase && currBase.id === base.id) {
                commit('setBaseIndex', { index, base })
              } else {
                commit('addBase', base)
              }
            } else {
              commit('deleteBase', index)
            }
          }
        }
        Couch.bases.allChanges(allChangesCallback)
        // Vue.prototype.$socketio.get().emit('getConnectedBases')
        dispatch('loadBases')
        dispatch('getConnectedBases')
      }
    },

    checkLoadedAll ({ state, commit }) {
      if (!state.loaded.all) {
        for (const key in state.loaded) {
          if (!state.loaded[key] && key !== 'all') {
            return
          }
        }
        commit('loadedAll')
      }
    },

    async getConnectedBases ({ state, commit, dispatch }) {
      try {
        commit('loading', { id: 'getConnectedBases', value: true })

        if (!state.loaded.getConnectedBases) {
          Vue.prototype.$socketio.addListener('updateConnectedBases', ({ basesConnected, removed, connected }) => {
            if (connected) {
              commit('showSnackbar', new Notifier(`${connected} foi conectado ao servidor`, {  }, 'success'), { root: true })
            }

            if (removed) {
              commit('showSnackbar', new Notifier(`${removed} foi desconectado do servidor`, {  }, 'error'), { root: true })
            }
            commit('setConnected', basesConnected)
            if (!state.loaded.getConnectedBases) {
              commit('setConnected', basesConnected)
              commit('loaded', { id: 'getConnectedBases', value: true })
              dispatch('checkLoadedAll')
              commit('loading', { id: 'getConnectedBases', value: false })
            }
          })
        }

        Vue.prototype.$socketio.get().emit('getConnectedBases')
      } catch (e) {
        commit('showSnackbar', new Notifier('Erro', { Erro: 'Não foi possível buscar Bases conectadas ao servidor' }, 'red'), { root: true })
      } finally {
        commit('loading', { id: 'getConnectedBases', value: false })
      }
    },

    async loadBases ({ state, commit, dispatch }) {
      if (!state.loaded.loadBases) {
        commit('loading', { id: 'loadBases', value: true })
        const allBases: Array<Base> = await Couch.bases.all()
        commit('setBases', allBases)
        commit('loading', { id: 'loadBases', value: false })

        if (!state.loaded.loadBases) {
          commit('loaded', { id: 'loadBases', value: true })
          dispatch('checkLoadedAll')
        }
      }
    },

    async addBase ({ commit, dispatch }, { id, label }: { [id: string]: any }): Promise<void> {
      commit('loading', { id: 'addBase', value: true })
      try {
        await Couch.bases.add(id, label)
        await dispatch('users/addUser', id, { root: true })
      } catch (e) {
        return Promise.reject(e)
      }
    },

    changeCurrentBase ({ commit }, base: Base) {
      commit('changeCurrentBase', base, { root: true })
    },
    
    async toggleStar ({ commit }, baseId: string): Promise<void> {
      try {
        commit('loading', { id: 'toggleStar', value: baseId })
        const base = await Couch.bases.pouch.get<any>(baseId)
        await Couch.bases.pouch.put({
          ...base,
          star: !base.star
        })
      } finally {
        commit('loading', { id: 'toggleStar', value: '' })
      }
    }
  },

  getters: {
    connectedBases (state) {
      const connected: Record<string, boolean> = {}
      for (const base of state.connected) {
        connected[base] = true
      }

      for (const base of state.bases) {
        if (!connected[base.id]) {
          connected[base.id] = false
        }
      }

      return connected
    },

    idToLabel (state) {
      const map = {}
      for (const base of state.bases) {
        map[base.id] = base.label
      }
      return map
    },

    idToBase (state): Record<string, Base> {
      const map: Record<string, Base> = {}
      for (const base of state.bases) {
        map[base.id] = base
      }
      return map
    },

    versions (state): string[] {
      const versions = {}
      for (const base of state.bases) {
        versions[base.workerVersion ?? '-1'] = true
      }
      delete versions['-1']

      const versionsArr = Object.keys(versions)
      versionsArr.sort().reverse()
      return versionsArr
    }
  }
}

export default BasesState
