import _Vue from 'vue'
import { customAlphabet } from 'nanoid'

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export function generateNanoId (length: number): () => string {
  const nanoId = customAlphabet(alphabet, length)
  return nanoId
}

declare module 'vue/types/vue' {
  interface Vue {
    $generateNanoId: (length: number) => () => void;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    Vue.prototype.$generateNanoId = generateNanoId
  }
}
