import { Module } from "vuex";
import { BackupEvent, EventState } from "./event.types";
import { RootState } from "@/store/types";
import Vue from 'vue'
import VueRouter from "vue-router";

const EventStore: Module<EventState, RootState> = {
  namespaced: true,
  state: {
    backupEvent: {},
    labels: [
      "Inicializando...",
      "Inicializando...",
      "GBAK...",
      "Comprimindo...",
      "Enviando...",
      "Finalizando...",
      "Concluído!",
      "Erro"
    ]
  },

  mutations: {
    reset (state) {
      state.backupEvent = {}
    },

    setEvent (state, payload: BackupEvent) {
      payload.message = state.labels[payload.state]
      Vue.set(state.backupEvent, payload.backupId, payload)
    }
  },

  actions: {
    joinEventRoom ({ rootState, commit }) {
      Vue.prototype.$socketio.joinRoom(`${rootState.currentBase?.id}/backup-event`, (event: BackupEvent) => {
        console.log(event)
        commit('setEvent', event)
      })

      Vue.prototype.$socketio.emitWait('get-backup-events', rootState.currentBase?.id)
    },

    leaveEventRoom ({ rootState, commit }) {
      Vue.prototype.$socketio.leaveRoom(`${rootState.currentBase?.id}/backup-event`)
      commit('reset')
    }
  }
}

export default EventStore
