import {Rules} from '@/plugins/rules'
import {Input} from '../types'
import { cloneDeep } from 'lodash'
import {generateNanoId} from '@/plugins/nanoid'
import { format } from 'date-fns' 
import {LogState} from './log/log.types'
import { LabelListDTO } from '../couch/types'
const rules: Rules = new Rules()

export interface BackupState {
  log?: LogState;
  logListEnable: boolean;
  backupConfigStatus: 'disabled' | 'create' | 'edit';
  backupConfigCurrent?: BackupConfig;
  labelList: Array<LabelListDTO>;
  triggersRegistered: boolean;
  loading: { [id: string]: boolean | any };
  error: { [id: string]: string | null };
}

export interface SchemaBackupItem {
  type: 'folder' | 'file';
  path: string;
  isBase: boolean;
  mask: string;
  fileName?: string;
  fullPath: string;
}

export interface SchemaBackupRule {
  type: 'exclusion' | 'overwrite';
  startIn: number; 
  iteration?: 'daily' | 'monthly' | 'weekly';
}

export interface SchemaBackupConfig {
  _id?: string;
  _rev?: string;
  label: string;
  days: Array<number>;
  dateTimes: Array<string>;
  items: Array<SchemaBackupItem>;
  rules: Array<SchemaBackupRule>;
  type: 'backup';
}

export interface SchemaListBackupItem {
  _id?: string;
  label: string;
  days: string;
}

export interface SchemaBackupOrder {
  _id?: string;
  type: 'start' | 'clean';
  backupId: string;
  creationTime: string;
  executionTime?: string;
}

export class BackupItem {
  public type: 'folder' | 'file' = 'file'
  public path: Input<string> = new Input('', [ rules.required, rules.path ])
  public isBase: boolean = false
  public fileName?: Input<string> = new Input('', [ rules.required, rules.fileName ])
  public mask: Input<string> = new Input('', [ rules.dateMask ])

  constructor (schema?: SchemaBackupItem) {
    if (schema) {
      this.type = schema.type
      this.path = new Input(schema.path, [rules.required, rules.path] )
      this.isBase = schema.isBase
      
      if (schema.fileName) {
        this.fileName = new Input(schema.fileName, [ rules.required, rules.fileName ])
      }

      this.mask = new Input(schema.mask, [ rules.dateMask ])
    }
    return this
  }

  public rules: { [id: string]: Array<Function> } = {
    path: [ rules.required, rules.path ],
    fileName: [ rules.required, rules.fileName ],
  }

  public validate (): boolean {
    if (process.env.NODE_ENV === 'development')
      return true

    if (!this.path.validate() || !this.mask.validate())
      return false

    if (this.type === 'file' && !this.fileName?.validate())
      return false

    return true
  }

  public getFullPath (): string {
    return `${ this.path.model }${ this.mask.model ? '{'+this.mask.model+'}\\' : '' }${ this.type === 'file' && this.fileName ? this.fileName.model : '' }`
  }

  public toJSON (): SchemaBackupItem {
    return {
      type: this.type,
      path: this.path.model,
      isBase: this.isBase,
      mask: this.mask.model,
      fileName: this.fileName?.model,
      fullPath: this.getFullPath(),
    }
  }
}

export class BackupRule {
  public type: 'exclusion' | 'overwrite' = 'exclusion'
  public iteration: 'daily' | 'monthly' | 'weekly' | undefined = undefined
  public startIn: Input<number> = new Input(0, [ rules.required ])

  get id(): string {
    return `${this.type}${this.iteration ?? ''}${this.startIn.model}`
  }

  constructor (schema?: SchemaBackupRule) {
    if (schema) {
      this.type = schema.type
      this.startIn = new Input(schema.startIn, [ rules.required ])
      if (schema.iteration)
        this.iteration = schema.iteration
    }
  }

  public validate (): boolean {
    if (!this.type)
      return false
    if (this.type === 'overwrite' && !this.iteration)
      return false
    if (this.type === 'exclusion' && this.startIn.model < 0)
      return false
    return true
  }

  public toJSON (): SchemaBackupRule {
    return {
      type: this.type,
      iteration: (this.type === 'exclusion') ? undefined : this.iteration,
      startIn: this.startIn.model,
    }
  }
}

export class BackupConfig {
  public _id?: string = undefined
  public _rev?: string = undefined
  public label: Input<string> = new Input('', [ rules.required ])
  public days: Input<Array<number>> = new Input([], [ rules.notEmpty ])
  public dateTimes: Input<Array<string>> = new Input([], [ rules.notEmpty ])
  public items: Input<Array<BackupItem>> = new Input([], [ rules.notEmpty ])
  public rules: Array<BackupRule> = []
  public error: { [id: string]: string } = {}

  constructor (schema?: SchemaBackupConfig) {
    if (schema) {
      const items = schema.items?.map(it => new BackupItem(it)) ?? []
      this._id = schema._id
      this._rev = schema._rev
      this.label = new Input(schema.label, [ rules.required ])
      this.days = new Input(schema.days, [ rules.notEmpty ])
      this.dateTimes = new Input(schema.dateTimes, [ rules.notEmpty ])
      this.items = new Input(items, [ rules.notEmpty ])
      this.rules = schema.rules?.map(it => new BackupRule(it)) ?? []
    }
    return this
  }

  public addItem (item: BackupItem): boolean {
    if (!item.validate())
      return false
    if (this.items.model.some(it => it.getFullPath() === item.getFullPath()))
      return false
    if (item.type === 'folder')
      item.isBase = false

    this.items.model.push(cloneDeep(item))
    return true
  }

  public addRule (rule: BackupRule): boolean {
    if (!rule.validate())
      return false
    if (this.rules.some(it => it.id === rule.id))
      return false
    this.rules.push(cloneDeep(rule))
    return true
  }

  public deleteItem (index: number) {
    this.items.model.splice(index, 1)
  }

  public deleteRule (index: number) {
    this.rules.splice(index, 1)
  }

  public validate (): boolean | any {
    const validateArr: Array<boolean> = [
      this.label.validate(),
      this.items.validate(),
      this.days.validate(),
      this.dateTimes.validate(),
    ]

    this.error = {
      'Nome Backup': this.label.error,
      'Arquivos': this.items.error,
      'Agendamento Semanal': this.days.error,
      'Horários': this.dateTimes.error,
    }

    return !validateArr.includes(false)
  }

  public newOrder (type: 'start' | 'clean'): SchemaBackupOrder {
    console.log('newOrder', type)
    if (this._id) {
      return {
        _id: `${this._id}:${generateNanoId(5)()}`,
        backupId: this._id,
        creationTime: format(new Date(), 'YYYY-MM-dd HH:mm'),
        executionTime: undefined,
        type,
      }
    }

    throw 'Backup id is undefined'
  }

  public toJSON (baseId?: string): SchemaBackupConfig {
    const json: SchemaBackupConfig = {
      _id: this._id,
      _rev: this._rev,
      label: this.label.model,
      days: this.days.model,
      dateTimes: this.dateTimes.model,
      items: this.items.model.map(item => item.toJSON()),
      rules: this.rules.map(rule => rule.toJSON()),
      type: 'backup',
    }

    if (!this._rev) {
      delete json._rev
    }

    if (!this._id) {
      json._id = `${baseId}:${generateNanoId(5)()}`
    }

    return json
  }
}
