import * as dotenv from 'dotenv';
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import PouchPlugin from '@/plugins/pouch'
import VueCookies from 'vue-cookies'
import Rules from '@/plugins/rules' 
import Socketio from './plugins/socketio'
import Notifier from '@/plugins/notifier'
import VuetifyConfirm from 'vuetify-confirm'
import Clipboard from 'v-clipboard'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

dotenv.config()
Vue.config.productionTip = false
Vue.use(PouchPlugin)
Vue.use(VueCookies)
Vue.use(Rules)
Vue.use(Socketio, router)
Vue.use(Clipboard)
Vue.use(Notifier)
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Aceitar',
  buttonFalseText: 'Cancelar',
  color: 'secondary',
  icon: 'fa-warning',
  title: 'Aviso',
  width: 350,
  property: '$confirm'
})

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  },
  confirm: {
    actions: {
      false: 'Não',
      true: {
        text: 'Sim',
        color: 'primary'
      }
    }
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
