import _Vue from 'vue'
import { isValid, format } from 'date-fns'

type Generic = string | number | Date
export type RuleFunction = (model: any) => boolean | string 

export class Rules {
  public required: RuleFunction = (v: any) => !!v || 'Obrigatório';
  public id: RuleFunction =  (v: string) => /^[a-z]+$/.test(v) || 'Formato inválido';
  public baseLabel: RuleFunction = (v: string) => /^([a-zA-Z\u00C0-\u00FF]+[  ]{0,1}[a-zA-Z\u00C0-\u00FF0-9]+)+$/.test(v) || 'Comece e termine com uma letra, não utilize caracteres especiais';
  public path: RuleFunction = (v: string) => /^([a-zA-Z]:)?(\\[^<>:"/\\|?*]+)+\\$/.test(v) || 'Formato inválido';
  public fileName: RuleFunction = (v: string) => /^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^|?\\:<>/$"]*[^.|*?\\:<>/$"]+$/.test(v) || 'Formato inválido';
  public notEmpty: RuleFunction = (v: Array<any>) => (v.length > 0) || 'Vazio';
  public dateTimeComma: RuleFunction = (v: string) => /^(10|11|12|[1-9]):[0-5][0-9],$/.test(v) || 'Formato inválido'
  public dateTime: RuleFunction = (v: string) => /^(10|11|12|[1-9]):[0-5][0-9]$/.test(v) || 'Formato inválido'
  public dateMask: RuleFunction = (v: string) => {
    try {
      if (v === '')
        return true
      return v && isValid(new Date(format(new Date(), v))) || 'Formato inválido'
    } catch (e) {
      return 'Formato inválido'
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $rules: Rules;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    Vue.prototype.$rules = new Rules()
  }
}
