import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find';
import config from '@/config'
import store from '@/store'
import { Log7DaysDTO, LogDTO } from './types'

PouchDB.plugin(PouchDBFind)

const pouch = new PouchDB<LogDTO>(`${config.CouchURL}/log`, {
  'skip_setup': true,
  fetch: function (url, opts) {
    if (opts) {
      opts.headers = new Headers(opts.headers)
      opts.headers.set('Authorization', `Basic ${store.state.auth?.authorization}`)
    }
    return PouchDB.fetch(url, opts)
  }
})

const pouch7Days = new PouchDB<Log7DaysDTO>(`${config.CouchURL}/log_7days`, {
  'skip_setup': true,
  fetch: function (url, opts) {
    if (opts) {
      opts.headers = new Headers(opts.headers)
      opts.headers.set('Authorization', `Basic ${store.state.auth?.authorization}`)
    }
    return PouchDB.fetch(url, opts)
  }
})

async function getLog (start: string, end: string): Promise<LogDTO[]> {
  let response: PouchDB.Find.FindResponse<LogDTO>
  let skip: number = 0
  const logs: LogDTO[] = []

  do {
    response = await pouch.find({
      selector: {
        startTime: {
          '$gt': start,
          '$lt': end
        }
      },
      skip,
      limit: 500,
    })
    skip += 500
    logs.push(...response.docs)
  } while (response.docs.length >= 500)

  return logs
}

async function getLog7Days (): Promise<Log7DaysDTO[]> {
  let response: PouchDB.Core.AllDocsResponse<Log7DaysDTO>
  let skip: number = 1
  const logs: Log7DaysDTO[] = []

  do {
    response = await pouch7Days.allDocs({
      include_docs: true,
      skip,
      limit: 50
    })

    for (const row of response.rows) {
      if (row.doc) {
        logs.push(row.doc)
      }
    }

    skip += response.rows.length
  } while (response.rows.length >= 50)

  return logs
}

export default {
  pouch,
  getLog7Days,
  getLog
}
