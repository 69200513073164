
import { RuleFunction } from '@/plugins/rules'
import { cloneDeep } from 'lodash'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class VTimeList extends Vue {
  @Prop({
    default: () => []
  })
  value!: Array<string>

  @Prop({ default: () => [] })
  rules!: Array<RuleFunction>

  @Prop({ default: false })
  disabled!: boolean

  @Ref()
  readonly textField!: HTMLInputElement

  errMsg: string = ''
  text: string = ''

  add () {
    if (this.text && !this.value.includes(this.text)) {
      const arr = cloneDeep(this.value)
      arr.push(this.text)
      arr.sort()
      this.$emit('input', arr)
      this.text = ''
      this.textField.focus()
    }
  }

  remove (index: number) {
    const arr = cloneDeep(this.value)
    arr.splice(index, 1)
    this.$emit('input', arr)
  }
}
