import { AuthState } from '@/store/auth/auth.types'
import { CoreState } from '@/store/core/core.types'
import { BackupState } from './backup/backup.types'
import { Base, BasesState } from './bases/bases.types'
import { Notifier} from '@/plugins/notifier'
import axios, { AxiosInstance } from 'axios'
import { UsersState } from './users/users.types'
import store from '@/store/index'
import config from '@/config'

export interface RootState {
  version: string;
  isDev: boolean;
  currentBase?: Base;
  snackbar?: Notifier;
  auth?: AuthState;
  core?: CoreState;
  backup?: BackupState;
  bases?: BasesState;
  users?: UsersState;
}

export interface ActionError {
  errorId: string;
  errorMsg: string;
}

export function getCurrentBaseFromLS (): Base | undefined {
  const id = localStorage.getItem('base_id')
  const label = localStorage.getItem('base_label')
  if (id && label) {
    return new Base(id, label, true)
  }
  return undefined
}

export function setCurrentBaseToLS (base: Base): void {
  localStorage.setItem('base_id', base.id)
  localStorage.setItem('base_label', base.label)
}

export class Input<Type> {
  public error: string = '' 

  constructor(
    public model: Type,
    public rules: Array<(model: any) => boolean | string>,
  ) {}

  validate (): boolean {
    this.error = ''
    for (const rule of this.rules) {
      if (rule(this.model) !== true) {
        this.error = `${rule(this.model)}`
        return false
      }
    }

    return true
    // return !this.rules.some(func => func(this.model) !== true)
  }
}

export enum changeStatus {
  ADDEDORUPDATED,
  DELETED,
}

const customAxios: AxiosInstance = axios.create({
  baseURL: config.CouchURL,
  withCredentials: true,
})

customAxios.interceptors.request.use(axiosRequestConfig => {
  const authorization = store.state.auth?.authorization
  if (authorization && !axiosRequestConfig.headers.Authorization) {
    axiosRequestConfig.headers.Authorization = `Basic ${authorization}`
  }
  return axiosRequestConfig
})

customAxios.interceptors.response.use(
  response => response,
  err => {
    let title: string = ''
    let body: { [id: string]: string } = {}
    if (err.response) {
      let msg = ''
      if (err.response.data.error && err.response.data.reason) {
        title = err.response.data.error
        msg = err.response.data.reason
      } else {
        title = 'Erro interno servidor'
        msg = 'Erro inesperado'
      }
      body[err.response.status] = msg
    } else if (err.request) {
      title = 'Erro interno do Servidor'
      body = { 500: 'Resposta não recebida' }
    } else {
      title = 'Erro interno do servidor'
      body = err.message
    }
    store.commit('showSnackbar', new Notifier(title, body, 'primary', 'right', 'bottom'))
    throw new Error(err)
  }
)

export const couchAxios = customAxios
