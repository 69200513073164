import PouchDB from 'pouchdb'
import {couchAxios, changeStatus} from '../types';
import { SchemaBackupConfig, SchemaBackupOrder } from '../backup/backup.types';
import config from '@/config'
import store from '@/store'
import { LabelListDTO, CouchView } from './types';

const pouch = new PouchDB(`${config.CouchURL}/backup`, {
  skip_setup: true,
  fetch: function (url, opts) {
    if (opts) {
      opts.headers = new Headers(opts.headers)
      opts.headers.set('Authorization', `Basic ${store.state.auth?.authorization}`)
    }
    return PouchDB.fetch(url, opts)
  }
})

async function add (data: SchemaBackupConfig): Promise<any> {
  return await couchAxios.post('backup', data)
}

async function getLabelList (baseId: string): Promise<LabelListDTO[]> {
  const response = await couchAxios.get<CouchView<LabelListDTO>>(`backup/_partition/${baseId}/_design/backup-info/_view/label-list`)
  return response.data.rows
}

async function getBackupById (id: string): Promise<SchemaBackupConfig> {
  return (await couchAxios.get<SchemaBackupConfig>(`backup/${id}`)).data
}

async function deleteBackupById (id: string, rev: string): Promise<void> {
  await couchAxios.delete(`backup/${id}`, { params: { rev } })
}

async function addOrder (data: SchemaBackupOrder): Promise<void> {
  await couchAxios.post('order', data)
}

// https://stackoverflow.com/questions/28059854/listening-to-pouchdb-changes-in-particular-view-returns-all-documents
async function changes (callback: Function) {
  pouch.changes({
    live: true,
    since: 'now',
    include_docs: true,
  }).on('change', function(change) {
    const doc: any = change.doc
    delete doc.id
    const backupConfig: SchemaBackupConfig = doc
    callback(null, backupConfig, change.deleted ? changeStatus.DELETED : changeStatus.ADDEDORUPDATED)
  }).on('error', function (err) {
    callback(err)
  })
}

export default {
  pouch,
  add,
  getLabelList,
  getBackupById,
  deleteBackupById,
  addOrder,
  changes,
}
