import { Module } from 'vuex';
import { RootState } from '@/store/types'
import { CoreState } from './core.types'

const CoreState: Module<CoreState, RootState> = {
  namespaced: true,

  state: {
    drawer: true,
  },

  mutations: {
    setDrawer (state, enabled: boolean) {
      state.drawer = enabled
    },
  },

  actions: {
    setDrawer ({ commit }, enabled: boolean) {
      commit('setDrawer', enabled)
    },
  },
}

export default CoreState
