
import { UserCredentials } from '@/store/auth/auth.types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'  

@Component
export default class Login extends Vue {
  background = require('@/assets/background.jpg');
  loading: boolean = false;
  loginError: string = '';
  counter: number = 0;
  credentials: UserCredentials = {
    username: '',
    password: '',
  }

  @Prop()
  public redirectTo!: string;

  async login () {
    this.loading = true
    const loggedIn = await this.$store.dispatch('auth/login', this.credentials)
    if (loggedIn) {
      this.$router.push({ name: this.redirectTo || 'home' })
    } else {
      this.loginError = 'Usuário ou senha incorretos'
    }
    this.loading = false
  }

  @Watch('credentials.username')
  onUsernameChange () {
    this.loginError = ''
  }
}   
