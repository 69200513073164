
import { Notifier } from '@/plugins/notifier';
import { Component, Ref, Vue } from 'vue-property-decorator'
import { Action, namespace } from 'vuex-class'
const basesModule = namespace('bases')

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => boolean;
  reset: () => void;
}

@Component
export default class Bases extends Vue {
  label: string = '';
  rulesErrorEnabled: boolean = true;

  @Ref('form')
  readonly form!: VForm;

  @Action('bases/addBase')
  addBase!: (obj: { [id: string]: any }) => void;

  @basesModule.State('error')
  error!: { [id: string]: boolean };
  
  @basesModule.State('loading')
  loading!: { [id: string]: string | null };

  get users (): { [id: string]: string } {
    return this.$store.state.users.users
  }

  get baseId (): string {
    return this.label.split(' ').join('_').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  }
  
  async send () {
    if (!this.form.validate())
      return

    if (this.users[this.baseId]) {
      this.$notifier(new Notifier('Erro de Validação', { 'Duplicado': 'Base já cadastrada' }, 'red'))
      return
    }

    if (this.form.validate()) {
      await this.addBase({ id: this.baseId, label: this.label })
      this.form.reset()
    }
  }
}
