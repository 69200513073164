import _Vue from 'vue'
import PouchDB from 'pouchdb'
import PouchDBAuthentication from 'pouchdb-authentication'
import config from '@/config'

declare module 'vue/types/vue' {
  interface Vue {
    $pouch: PouchDB.Database;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    PouchDB.plugin(PouchDBAuthentication)
    const db = new PouchDB(config.CouchURL, {
      'skip_setup': true,
      fetch: function (url, opts) {
        if (opts) {
          opts.credentials = 'include';
        }
        return PouchDB.fetch(url, opts)
      }
    });
    Vue.prototype.$pouch = db
  }
}
