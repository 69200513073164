
import { Base } from '@/store/bases/bases.types'
import { Component, Vue } from 'vue-property-decorator'
import { Action, namespace } from 'vuex-class'
import BaseList from './BaseList.vue'
import NovaBase from './NovaBase.vue'

const basesModule = namespace('bases')

@Component({
  components: {
    NovaBase,
    BaseList,
  }
})
export default class Bases extends Vue {
  base: Base = {
    id: '',
    label: '',
    star: false,
  }

  formValid: boolean = false;

  @Action('bases/addBase')
  addBase!: (base: Base) => void;

  @basesModule.State('error')
  error!: { [id: string]: boolean };
  
  @basesModule.State('loading')
  loading!: { [id: string]: string | null };

  @basesModule.State('loaded')
  loaded!: { [id: string]: string | null };

  mounted () {
    // this.$store.dispatch('bases/loadBases')
    this.$store.dispatch('users/loadUsers')
  }
}
