import PouchDB from 'pouchdb'
import {couchAxios, changeStatus } from '../types';
import {Base} from '../bases/bases.types';
import config from '@/config'
import store from '@/store'

const pouch = new PouchDB(`${config.CouchURL}/bases`, {
  'skip_setup': true,
  fetch: function (url, opts) {
    if (opts) {
      opts.headers = new Headers(opts.headers)
      opts.headers.set('Authorization', `Basic ${store.state.auth?.authorization}`)
    }
    return PouchDB.fetch(url, opts)
  }
})

export async function add(id: string, label: string): Promise<any> {
  return await couchAxios.post('bases', {
    _id: id,
    label: label,
    enabled: true,
  })
}

export async function all (): Promise<Array<Base>> {
  return (await pouch.allDocs({ 'include_docs': true })).rows.map(it => {
    const doc: any = it.doc 
    return {
      id: doc._id,
      label: doc.label,
      enabled: doc.enabled,
      star: doc.star,
      workerVersion: doc.workerVersion
    }
  })
}

export function allChanges (callback: Function) {
  pouch.changes({
    live: true,
    since: 'now',
    include_docs: true,
  }).on('change', function (change) {
    const doc: any = change.doc
    doc.id = doc._id
    delete doc._id
    const base: Base = { ...doc }

    if (change.deleted)
      callback(null, base, changeStatus.DELETED)
    else
      callback(null, base, changeStatus.ADDEDORUPDATED)
  }).on('error', function (err) {
    callback(err)
  })
}

export default {
  add,
  all,
  allChanges,
  pouch,
}
