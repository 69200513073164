
import { Base } from '@/store/bases/bases.types';
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class AppBar extends Vue {
  @Action('core/setDrawer')
  setDrawer!: (enabled: boolean) => void;

  get drawer () {
    return this.$store.state.core.drawer
  }

  get currentBase (): Base {
    return this.$store.state.currentBase
  }
}
