
import { Component, Vue } from 'vue-property-decorator'
import VBackupConfig from './BackupConfig.vue'
import VBackupList from './BackupList.vue'
import VLogList from './LogList.vue'
import { namespace } from 'vuex-class'
import { Base } from '@/store/bases/bases.types'
import { LabelListDTO } from '@/store/couch/types'

const backupModule = namespace('backup')
const logModule = namespace('backup/log')
const eventModule = namespace('backup/event')

@Component({
  components: {
    VBackupConfig,
    VBackupList,
    VLogList,
  }
})
export default class Backup extends Vue {
  @backupModule.Mutation('toggleLogListEnable')
  toggleLogList!: () => void

  @backupModule.State('logListEnable')
  logListEnable!: boolean

  @backupModule.Mutation('statusBackupConfig')
  statusBackupConfig!: (status: 'create' | 'edit' | 'disabled') => void;

  @backupModule.Mutation('setLabelList')
  setLabelList!: (payload: LabelListDTO[]) => void;

  @backupModule.Action('registerTriggers')
  registerTriggers!: () => void;

  @eventModule.Action('joinEventRoom')
  joinEventRoom!: () => void;

  @eventModule.Action('leaveEventRoom')
  leaveEventRoom!: () => void;

  @logModule.Action('joinLogRoom')
  joinLogRoom!: () => void;

  @logModule.Action('leaveLogRoom')
  leaveLogRoom!: () => void; 

  @logModule.Action('getLog')
  getLog!: () => void;

  @logModule.Mutation('clear')
  clearLog!: () => void

  @backupModule.State('loading')
  loadingBackup!: { [id: string]: boolean }

  @logModule.State('loading')
  loadingLog!: { [id: string]: boolean }

  get currentBase (): Base {
    return this.$store.state.currentBase
  }

  created () {
    this.statusBackupConfig('create')
    this.setLabelList([])
    this.joinLogRoom()
    this.joinEventRoom()
    this.getLog()
  }

  destroyed () {
    this.leaveLogRoom()
    this.leaveEventRoom()
    this.clearLog()
  }
}
