
import { Component, Vue } from "vue-property-decorator";
import { LogResume } from '@/store/backup/log/log.types'
import { LogDTO } from '@/store/couch/types'
import format from "date-fns/format";
import { ptBR } from 'date-fns/locale'
import { throttle } from "lodash";

@Component
export default class MonitorCalendar extends Vue {
  page: number = 1
  pageCount: number = 0
  itemsPerPage: number = 25
  logs: LogDTO[] = []
  loaded: boolean = false
  loading: boolean = false
  containerWidth: string = '0'
  containerHeight: string = '0'
  search: string = ''
  menu: boolean = false
  dialogLogList: any[] = []
  dialogTitle: string = ''

  headers: any = [
    {
      text: 'Empresa',
      align: 'start',
      sortable: false,
      value: 'label',
      width: '200px',
    },
    { text: 'Ago. 27', value: 'days.day1', sortable: false, width: '100px', align: 'center' },
    { text: 'Ago. 26', value: 'days.day2', sortable: false, width: '100px', align: 'center' },
    { text: 'Ago. 25', value: 'days.day3', sortable: false, width: '100px', align: 'center' },
    { text: 'Ago. 24', value: 'days.day4', sortable: false, width: '100px', align: 'center' },
    { text: 'Ago. 23', value: 'days.day5', sortable: false, width: '100px', align: 'center' },
    { text: 'Ago. 22', value: 'days.day6', sortable: false, width: '100px', align: 'center' },
    { text: 'Ago. 21', value: 'days.day7', sortable: false, width: '100px', align: 'center' },
  ]

  mounted () {
    this.getContainerBound()
    window.addEventListener('resize', throttle(this.getContainerBound, 250))
    this.refresh()
  }

  async refresh () {
    this.loading = true
    await this.$store.dispatch('backup/log/getResume')
    const days: string[] = this.$store.state.backup.log.resumeDays

    for (const i in this.headers) {
      if (this.headers[i].value !== 'label') {
        // @ts-ignore
        this.headers[i].text = format(new Date(`${days[i - 1]} 00:00`), 'E dd/MM', { locale: ptBR })
      }
    }

    this.loading = false
  }

  getContainerBound () {
    this.loaded = false
    this.$nextTick(() => {
      const container = this.$refs.container as Element
      if (container) {
        this.containerWidth = container.getBoundingClientRect().width.toFixed(0)
        this.containerHeight = (window.innerHeight - container.getBoundingClientRect().top - 85).toFixed(0)
        this.loaded = true
      }
    })
  }

  getIcon (errors: any[], success: any[]) {
    if (errors.length === 0 && success.length === 0) {
      return 'fa-exclamation-triangle'
    }

    if (errors.length > 0) {
      return 'fa-exclamation-circle'
    }

    return 'fa-check-circle'
  }

  getColor (errors: any[], success: any[]) {
    if (errors.length === 0 && success.length === 0) {
      return 'orange'
    }

    if (errors.length > 0) {
      return 'red'
    }

    return 'green'
  }

  openDialog (errors: any[], success: any[], title) {
    this.dialogTitle = title
    this.menu = true
    this.dialogLogList = errors.concat(success)
    console.log(this.dialogLogList)
    // @ts-ignore
    return this.dialogLogList.sort((a, b) => {
      return a.startTime > b.startTime
    })
  }

  get filteredResume () {
    const resume = this.$store.state.backup.log.resume as LogResume[]
    return resume.filter(item => {
      const label = item.label.toLowerCase()
      const search = this.search.toLowerCase()
      return label.search(search) >= 0
    })
  }
}
