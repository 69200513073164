import {Module} from "vuex";
import {LogState, LogListItem, GetLogDTO, LastBackupLog, LogResume, LogResumeDays} from './log.types';
import {RootState} from '@/store/types';
import Couch from '@/store/couch' 
import Vue from 'vue'
import {Base} from '@/store/bases/bases.types';
import {addDays, format} from 'date-fns'
import { cloneDeep } from 'lodash'
import { LogDTO } from "@/store/couch/types";

const LogState: Module<LogState, RootState> = {
  namespaced: true,

  state: {
    list: [],
    skip: 0,
    limit: 60,
    loadedAllLog: false,
    resume: [],
    resumeDays: [],
    loading: {
      loadLogList: false,
      joinRoom: false,
      getLog: false,
      getResume: false,
    },
    loaded: {
      getResume: false,
    },
    error: {
      loadLogList: null,
    },
    lastBackupLog: {},
    triggersRegistered: false
  },

  mutations: {
    setResume (state, { resume, resumeDays }) {
      state.resumeDays = resumeDays
      state.resume = resume
      Object.freeze(state.resume)
    },

    clear (state) {
      state.list = []
      state.skip = 0
      state.loadedAllLog = false
    },

    addLog (state, log: LogListItem) {
      state.list.push(log)
    },

    setLogList (state, logList: LogListItem[]) {
      state.list = logList
    },

    concatLogList (state, logList: LogListItem[]) {
      state.list.unshift(...logList)
    },

    updateSkip (state) {
      state.skip += state.limit
    },

    loadedAllLog (state) {
      state.loadedAllLog = true
    },

    loading (state, { id, value }) {
      state.loading[id] = value
    },

    setTriggersRegistered (state, payload: boolean) {
      state.triggersRegistered = payload
    },

    setLastBackupLog (state, payload: { [id: string]: LastBackupLog }) {
      state.lastBackupLog = payload
    },

    loaded (state, { id, value }) {
      state.loaded[id] = value
    }
  },

  actions: {
    registerTriggers ({ state, commit }) {
      if (!state.triggersRegistered) {
        commit('setTriggersRegistered', true)
        Vue.prototype.$socketio.addListener('updateLastLog', lastLog => {
          commit('setLastBackupLog', lastLog)
        })
        Vue.prototype.$socketio.emitWait('lastLog')
      }
    },

    joinLogRoom ({ rootState, commit }) {
      Vue.prototype.$socketio.joinRoom(`${rootState.currentBase?.id}/log`, (logItem: LogListItem) => {
        commit('addLog', logItem)
      })
    },

    async getLog ({ commit, rootState, state }) {
      if (rootState.currentBase?.id) {
        commit('loading', { id: 'getLog', value: true })
        const data: LogListItem[] = await Vue.prototype.$socketio.emitWait('getLog', {
          skip: state.skip,
          limit: state.limit,
          baseId: rootState.currentBase.id
        } as GetLogDTO)

        if (data.length) {
          commit('updateSkip')
          commit('concatLogList', data)
        } else {
          commit('loadedAllLog')
        }
        commit('loading', { id: 'getLog', value: false })
      }
    },

    leaveLogRoom ({ rootState }) {
      Vue.prototype.$socketio.leaveRoom(`${rootState.currentBase?.id}/log`)
    },

    async getResume ({ state, commit, dispatch, rootState }, force: boolean = false) {
      if (force || !state.loaded.getResume) {
        commit('loading', { id: 'getResume', value: true })
        const resume: LogResume[] = []
        await dispatch('bases/loadBases', null, { root: true })
        if (rootState.bases?.bases) {
          const bases: Base[] = rootState.bases?.bases
          const days: string[] = []
          const dayToKey: Record<string, string> = {}
          const daysArr: LogResumeDays = {}
          const baseToIndex: Record<string, number> = {}

          for (let i = 0; i < 7; i++) {
            const day = format(addDays(new Date(), -i), 'yyyy-MM-dd')
            dayToKey[day] = `day${i + 1}`
            days.push(day)
          }

          const logs: LogDTO[] = await Couch.log.getLog(`${days[6]} 00:00`, `${days[0]} 23:59`)

          for (const day of days) {
            daysArr[dayToKey[day]] = { error: [], success: [] }
          }

          for (const [index, base] of bases.entries()) {
            resume.push({
              id: base.id,
              label: base.label,
              days: cloneDeep(daysArr)
            })
            baseToIndex[base.id] = index 
          }

          for (const log of logs) {
            const baseId = log._id.split(':')[0]
            const dayKey = dayToKey[log.startTime.split(' ')[0]]
            const key = log.error ? 'error' : 'success'
            if (dayKey) {
              resume[baseToIndex[baseId]].days[dayKey][key].push(log)
            }
          }

          commit('setResume', { resume, resumeDays: days })
          commit('loaded', { id: 'getResume', value: true })
        }

        commit('loading', { id: 'getResume', value: false })
      }
    }
  },
}

export default LogState
