export interface AuthState {
  authorization?: string;
  socketConnected: boolean;
  checkAuth: boolean;
  loggedIn: boolean;
  loginError: string;
  username?: string;
}

export interface UserCredentials {
  username: string;
  password: string;
}

export function getAuthFromLS () {
  const authorization = localStorage.getItem('authorization')
  if (authorization) {
    return {
      authorization,
      name: atob(authorization.split(':')[0])
    }
  }
  return {
    authorization: undefined,
    name: undefined,
  }
}

export function setAuthToLS (authorization: string) {
  localStorage.setItem('authorization', authorization)
}

export function removeAuthFromLS () {
  localStorage.removeItem('authorization')
}
