import PouchDB from 'pouchdb'
import { couchAxios } from '@/store/types'
import { TokenPerUserDTO, CouchView } from './types'

const pouch = new PouchDB(`${couchAxios.defaults.baseURL}/_users`, {
  skip_setup: true,
  fetch: function (url, opts) {
    if (opts) {
      opts.credentials = 'include'
    }
    return PouchDB.fetch(url, opts)
  }
})

export async function add(id: string, password: string): Promise<any> {
  return await couchAxios.post('_users', {
    _id: `org.couchdb.user:${id}`,
    name: id,
    password: password,
    token: password,
    type: 'user',
    roles: ['base'],
  })
}

export async function all(): Promise<{ [id: string]: string }> {
  const allDocs: Array<TokenPerUserDTO> = (await couchAxios.get<CouchView<TokenPerUserDTO>>('_users/_design/all/_view/token-per-user')).data.rows
  return allDocs.reduce((prev: { [id: string]: string }, curr) => {
    prev[curr.key] = curr.value.token
    return prev
  }, {})
}

export default {
  add,
  all,
  pouch,
}
