
import { Component, Prop, Vue } from 'vue-property-decorator'
import { RouteConfig } from 'vue-router';
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('auth', [
      'loggedIn',
      'socketConnected',
    ])
  }
})
export default class CheckAuth extends Vue {
  public loggedIn!: string;
  public socketConnected!: boolean;
  public status: string = ''

  @Prop()
  public redirectTo!: RouteConfig;

  get redirect () {
    return this.redirectTo?.name ?? 'home'
  }

  mounted () {
    this.auth()
  }

  async authSession (): Promise<boolean> {
    this.status = 'authSession'
    const ret = await this.$store.dispatch('auth/session')
    this.status = ''
    return ret
  }

  async socketioConnect () {
    this.status = 'socketioConnect'
    const connect = await this.$socketio.connect()
    if (connect === false) {
      return new Promise(resolve => {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'auth/setSocketConnected' && mutation.payload === true) {
            this.status = ''
            resolve(true)
          }
        })
      })
    } else {
      this.status = ''
    }
  }

  async auth () {
    if (await this.authSession()) {
      await this.socketioConnect()
      this.$store.dispatch('auth/checkAuth', true)
      if (this.loggedIn && this.socketConnected) {
        return this.$router.push({ name: this.redirect })
      }
    }

    return this.$router.push({ name: 'login', params: { redirectTo: this.redirect } })
    /*
    await this.socketioConnect()
    this.$store.dispatch('auth/checkAuth', true)
    if (this.loggedIn && this.socketConnected) {
      this.$router.push({ name: this.redirect })
    } else {
      this.$router.push({ name: 'login', params: { redirectTo: this.redirect } })
    }
    */
  }
}
