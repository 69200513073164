import _Vue from 'vue'
import store from '@/store'

export class Notifier {
  constructor(
    public title: string = 'Erro de validação',
    public content: { [id: string]: string } = { },
    public color: string = 'success',
    public halign: string = 'right',
    public valign: string = 'top',
  ) {  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $notifier: (notifier: Notifier) => void;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    Vue.prototype.$notifier = function(notifier: Notifier) {
      store.commit('showSnackbar', notifier)
    }
  }
}
