import bases from './bases.couch'
import users from './users.couch'
import backup from './backup.couch'
import auth from './auth.couch'
import log from './log.couch'

export default {
  bases,
  users,
  backup,
  auth,
  log
}
