import { couchAxios } from '../types'

export async function login (name: string, password: string): Promise<any> {
  const authorization = `Basic ${btoa(`${name}:${password}`)}`
  const user = await couchAxios.get('/_session', {
    headers: {
      authorization,
    },
  })

  return user.data
}

export async function checkSession (authorization: string): Promise<any> {
  const user = await couchAxios.get('/_session', {
    headers: {
      Authorization: 'Basic ' + authorization,
    },
  })

  return user.data
}

export default {
  login,
  checkSession,
}
