
import { BackupConfig } from '@/store/backup/backup.types'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { SchemaBackupOrder } from '@/store/backup/backup.types'
import { generateNanoId } from '@/plugins/nanoid'
import { format } from 'date-fns'
import { BackupDTO, LabelListDTO } from '@/store/couch/types'
import { BackupEvent } from '@/store/backup/event/event.types'

const backupModule = namespace('backup')
const eventModule = namespace('backup/event')

@Component
export default class VBackupList extends Vue {
  @eventModule.State('backupEvent')
  backupEvent!: Record<string, BackupEvent>

  @backupModule.State('backupConfigCurrent')
  backupConfigCurrent!: BackupConfig

  @backupModule.State('loading')
  loading!: { [id: string]: boolean }

  @backupModule.State('labelList')
  labelList!: Array<LabelListDTO>;

  @backupModule.Action('loadLabelList')
  loadLabelList!: () => Promise<LabelListDTO[]>;

  @backupModule.Action('getBackupById')
  getBackupById!: (id: string) => Promise<BackupDTO>;

  @backupModule.Action('addOrder')
  addOrder!: (data: SchemaBackupOrder) => Promise<void>;

  @backupModule.Action('startBackup')
  startBackup!: (id: string) => void;

  @backupModule.Mutation('statusBackupConfig')
  statusBackupConfig!: (status: 'create' | 'edit' | 'disabled') => void;

  public newOrder (id: string, type: 'start' | 'clean'): void {
    this.addOrder({
      _id: `${id}:${generateNanoId(5)()}`,
      backupId: id,
      creationTime: format(new Date(), 'yyyy-MM-dd HH:mm'),
      executionTime: undefined,
      type,
    })
  }

  public onClickMessage (backupId: string) {
    const backupEvent = this.backupEvent[backupId]
    if (backupEvent.error_message) {
      this.$dialog.error({
        title: "Erro:",
        text: backupEvent.error_message,
        persistent: false,
      });
    }
  }

  dayNames: Array<string[]> = [
    ['D', 'Domingo'], 
    ['S', 'Segunda-Feira'],
    ['T', 'Terça-Feira'],
    ['Q', 'Quarta-Feira'],
    ['Q', 'Quinta-Feira'],
    ['S', 'Sexta-Feira'],
    ['S', 'Sábado'],
  ]

  mounted () {
    this.loadLabelList()
  }
}
