
import { Notifier } from '@/plugins/notifier'
import { RootState } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Snackbar extends Vue {
  config: Notifier = new Notifier()
  show: boolean = false

  get content () {
    const content: { [id: string]: string } = {}
    for (const key in this.config.content) {
      if (this.config.content[key]) {
        content[key] = this.config.content[key]
      }
    }

    return content
  }

  created () {
    this.$store.subscribe((mutation, state: RootState) => {
      if (mutation.type === 'showSnackbar' && state.snackbar) {
        // console.log(state.snackbar)
        // this.$dialog.notify.info(state.snackbar.title)
        this.config = state.snackbar
        this.show = true
      }
    })
  }
}
