import { format, addDays } from "date-fns"
import { ptBR } from "date-fns/locale"

export const generateDaysAndKeys = (): { days: string[]; daysLabels: Record<string, string>; sortByDay: Record<string, 'ASC' | 'DESC' | 'AUTO'> } => {
  const days: string[] = []
  const daysLabels: Record<string, string> = {}
  const sortByDay: Record<string, 'ASC' | 'DESC' | 'AUTO'> = {}

  for (let i = 0; i < 7; i++) {
    const day = format(addDays(new Date(), -i), 'yyyy-MM-dd')
    const label = format(new Date(`${day} 00:00`), 'cccccc dd/MM', { locale: ptBR })
    daysLabels[day] = label
    days.push(day)
    sortByDay[day] = 'AUTO'
  }

  return {
    days,
    daysLabels,
    sortByDay
  }
}
