
import { Component, Vue } from 'vue-property-decorator'
import CoreAppBar from '@/views/core/AppBar.vue'
import CoreDrawer from '@/views/core/Drawer.vue'
import Snackbar from '@/views/core/Snackbar.vue'

@Component({
  components: {
    CoreAppBar,
    CoreDrawer,
    Snackbar,
  },
})
export default class Index extends Vue {}
