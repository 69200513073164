import _Vue from 'vue'
import config from '@/config'
import store from '@/store/index'
import io from 'socket.io-client'
import VueRouter from 'vue-router'

declare module 'vue/types/vue' {
  interface Vue {
    $socketio: {
      get: () => any;
      connect: () => Promise<boolean>;
      disconnect: () => void;
      joinRoom: (room: string) => Promise<void>;
      addListener: (event: string, callback: Function) => void;
      removeListener: (event: string) => void;
      emitWait: (event: string, data: any) => Promise<any>;
    };
  }
}

export default {
  install: (Vue: typeof _Vue, router: VueRouter) => {
    let socket: any = null
    const rooms: Set<string> = new Set()
    const listeners: Map<string, Function> = new Map()

    const addListener = (event: string, callback: Function) => {
      if (!listeners.has(event)) {
        listeners.set(event, callback)
        socket.on(event, callback)
      }
    }

    const removeListener = (event: string) => {
      socket.off(event)
      listeners.delete(event)
    }

    const emitWait = async (event: string, data: any, returnEvent?: string) => {
      return new Promise(resolve => {
        addListener(returnEvent ?? event, (result: any) => {
          removeListener(event)
          resolve(result ?? true)
        })
        socket.emit(event, data)
      })
    }

    const joinRoom = async (room: string, callback: (Function | undefined) = undefined) => {
      if (!rooms.has(room)) {
        console.log(`[socketio] Join Room "${room}"`)
        new Promise(resolve => {
          const joinRoomFunc = function (listenerRoom: string) {
            if (listenerRoom === room) {
              socket.off('joinRoom', joinRoomFunc)
              if (callback) {
                addListener(room, callback)
              }
              resolve(listenerRoom)
            }
          }
          socket.on('joinRoom', joinRoomFunc)
          socket.emit('joinRoom', room)
        })
      }
      rooms.add(room)
    }

    const leaveRoom = async (room: string) => {
      if (rooms.has(room)) {
        console.log(`[socketio] Leave Room "${room}"`)
        new Promise(resolve => {
          const leaveRoomFunc = function (listenerRoom: string) {
            if (listenerRoom === room) {
              socket.off('leaveRoom', leaveRoomFunc)
              removeListener(room)
              resolve(listenerRoom)
            }
          } 
          socket.on('leaveRoom', leaveRoomFunc)
          socket.emit('leaveRoom', room)
        })
      }
      rooms.delete(room)
    }

    const onConnect = async () => {
      const promises: Function[] = []
      for (const room of rooms) {
        promises.push(async () => await joinRoom(room))
      }
      await Promise.all(promises)
      store.commit('auth/setSocketConnected', true)
    }

    const onError = (err: any) => {
      console.log('on error', err)
    }

    const onDisconnect = (reason: string) => {
      rooms.clear()
      console.log(`[socketio] On Disconnect "${reason}"`)
      store.commit('auth/setSocketConnected', false)

      if (reason === 'io server disconnect' || reason === 'io client disconnect') {
        socket.close()
      }

      router.push({ name: 'check-auth', params: { redirectTo: router.currentRoute as any }})
    }

    Vue.prototype.$socketio = {
      get: () => socket,
      joinRoom,
      leaveRoom,
      addListener,
      emitWait,
      removeListener,

      async connect (): Promise<boolean> {
        if (socket) return false

        return new Promise<boolean> ((resolve, reject) => {
          socket = io(config.SocketURL, {
            path: '/api/socketio',
            transports: ['websocket'],
            query: {
              authorization: store.state.auth?.authorization ?? '',
            }
          })
          socket.on('error', (err: any) => { onError(err); reject(err) })
          socket.on('connection_error', (err: any) => { onError(err); reject(err) })
          socket.on('connect', () => { onConnect(); resolve(true) })
          socket.on('disconnect', (reason: string) => onDisconnect(reason))
        })
      },

      disconnect () {
        socket.disconnect()
      },
    }
  }
}
