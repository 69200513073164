import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState, getCurrentBaseFromLS, setCurrentBaseToLS } from './types'
import auth from './auth/auth.store'
import core from './core/core.store'
import backup from './backup/backup.store'
import bases from './bases/bases.store'
import users from './users/users.store'
import {Base} from './bases/bases.types'
import {Notifier} from '@/plugins/notifier'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
    isDev: process.env.NODE_ENV === 'development',
    currentBase: getCurrentBaseFromLS(),
    snackbar: undefined, 
    auth: undefined,
    core: undefined,
    backup: undefined,
    bases: undefined,
    users: undefined,
  },

  mutations: {
    changeCurrentBase (state, base: Base) {
      state.currentBase = base
      setCurrentBaseToLS(base)
    },

    showSnackbar (state, payload: Notifier) {
      state.snackbar = payload
    },
  },

  actions: {
    registerGlobalTriggers ({ dispatch }) {
      dispatch('bases/registerTriggers')
      dispatch('backup/registerTriggers')
      dispatch('backup/log/registerTriggers')
    },
  },

  modules: {
    auth,
    core,
    backup,
    bases,
    users,
  },
}

export default new Vuex.Store<RootState>(store);
