
import { Base } from '@/store/bases/bases.types'
import { Component, Vue } from 'vue-property-decorator'
import VInfiniteLoading from 'vue-infinite-loading'
import { namespace } from 'vuex-class'

const basesModule = namespace('bases')

@Component({
  components: {
    VInfiniteLoading,
  }
})
export default class BaseList extends Vue {
  list: Array<Base> = []
  search: string = ''
  index: number = 0
  offset: number = 5
  infiniteId: number = +new Date()
  hoverIndex: number | null = null 

  @basesModule.State('loading')
  loading!: Record<string, boolean | string>

  @basesModule.Action('toggleStar')
  toggleStar!: (baseId: string) => Promise<void>

  get users (): { [id: string]: string } {
    return this.$store.state.users.users
  }

  get bases (): Array<Base> {
    return this.$store.state.bases.bases
  }

  get basesLength (): number {
    return this.bases.length
  }

  get currentBase (): string {
    return this.$store.state.currentBase?.id
  }

  set currentBase (value: string) {
    this.$store.commit('changeCurrentBase', value)
  }
  
  get filteredBases (): Array<Base> {
    // this.resetInfinityHandler()
    return this.bases.filter(it => {
      const label = it.label.toLowerCase()
      const search = this.search.toLowerCase()
      return label.search(search) >= 0
    })
  }
}
