
import { LogListItem } from '@/store/backup/log/log.types'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const backupModule = namespace('backup')
const logModule = namespace('backup/log')

@Component
export default class LogList extends Vue {
  public height: number = 0
  public width: number = 0
  public keepBottom: boolean = true

  @backupModule.Mutation('toggleLogListEnable')
  toggleLogList!: () => void;

  @logModule.State('list')
  logList!: LogListItem[] 

  @logModule.Action('getLog')
  getLog!: () => void;

  @logModule.State('loadedAllLog')
  loadedAllLog!: boolean

  mounted () {
    this.updateDimension()
    window.addEventListener('resize', () => this.updateDimension());
    this.$store.subscribe(mutation => {
      if (mutation.type === 'backup/log/addLog') {
        this.scrollToBottom()
      }
    })
  }

  updateDimension () {
    this.$nextTick(() => {
      const element = document.getElementById('log-container')
      const top = element?.getBoundingClientRect().top ?? 0
      const height = window.innerHeight
      this.height = height - top - 55
      this.width = (element?.offsetWidth ?? 1)
      this.scrollToBottom()
    })
  }

  scrollToBottom (force = false) {
    if (force || this.keepBottom) {
      this.$nextTick(() => {
        const logBookEl = document.getElementById('log-book')
        if (logBookEl) {
          logBookEl.scrollTop = logBookEl.scrollHeight
        }
      })
    }
  }
}
